.supply-page {
  margin-top: 4rem;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9; /* Add a subtle background */
}

.products-grid {
  display: flex;
  justify-content: center; /* Center items */
  flex-wrap: wrap;
  gap: 20px; /* Add some gap between product cards */
}

.product-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  width: 250px;
  text-align: left;
  background-color: white; /* Add a white background to the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for the card */
}

.product-card:hover {
  transform: scale(1.03); /* Scale up slightly on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Enhance the shadow on hover */
}

.product-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px; /* Rounded image corners to match the card */
}

.product-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #333; /* Darker color for better contrast */
}

.product-card p {
  font-size: 1rem;
  margin: 5px 0;
  color: #555; /* Subtle text color */
}

button {
  background-color: #27ae60;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Make button full width */
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2ecc71;
}

.cart-summary {
  margin-top: 30px;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for cart summary */
}

.cart-summary ul {
  list-style: none;
  padding: 0;
}

.cart-summary li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333;
}

.checkout-btn {
  background-color: #e67e22;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%; /* Full width button */
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #d35400;
}

/* Mobile View */
@media only screen and (max-width: 600px) {
  .product-card {
    width: 90%; /* Make the card wider on small screens */
    margin: 20px auto; /* Center the card on mobile */
  }
  
  button, .checkout-btn {
    font-size: 0.9rem;
  }
}
