.info-page {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #f7f7f7;
  font-family: "Arial", sans-serif;
  min-height: 70vh;
}
.h3-infoP {
  margin-top: 1rem;
  border: 1px solid yellow;
  
}

.content-container {
  background: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  color: #2a9d8f;
}

.content-text {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  
}

.faq-section {
  margin-top: 1.5rem;
}

.faq-item {
  margin-bottom: 1.5rem;
}

.faq-question {
  font-size: 1.4rem;
  color: #264653;
}

.faq-answer {
  font-size: 1rem;
  color: #555;
}

a {
  color: #2a9d8f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
