.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../images/blurred2.jpg");
  background-position: center;
  background-size: contain;
  height: 40vh;
  width: 100%;
}

.footer-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  height: 35vh;
  padding-left: 2rem;
  overflow: auto;
}

.name-input > input {
  color: grey;
  border: 1px solid grey;
  margin-right: 1rem;
  width: 40%;
  margin-bottom: 1rem;
  color: black;
}

.contact-input > input {
  color: grey;
  border: 1px solid grey;
  margin-right: 1rem;
  width: 40%;
  color: black;
}

.contact-input > button {
  width: 40%;
  background-color: #44ba00;
  border: none;
  color: white;
}

@media (max-width: 768px) {
  .name-input > input,
  .contact-input > input {
    width: 80%;
  }
  .contact-input > button {
    width: 80%;
    margin-top: 1rem;
  }

  .footer-right {
    width: 50%;
    font-size: 0.8rem;
  }
}


.footer-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  background-color: #f7e5e5;
  width: 100%;
  height: auto; /* Allow footer height to adjust automatically */
  flex-wrap: wrap; /* Wrap sections to avoid overlap */
  gap: 0.1rem; /* Add spacing between sections */
  padding-top: 1rem;
}

/* Logo Section */
.part-1 {
  flex: 1;
  text-align: center;
  margin: auto auto;
}

.part-1 img {
  height: 6rem;
  width: 6rem;
  
}

.social-div {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
}

.social-div a img {
  height: 2rem;
  width: 2rem;
  transition: transform 0.3s ease;
}

.social-div a:hover img {
  transform: scale(1.1); /* Enlarge icons slightly on hover */
}

/* Company and Support Sections */
.part-2, .part-3, .part-4 {
  flex: 1;
  text-align: center;
}

.footer-h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.footer-h3::after {
  content: "";
  display: block;
  width: 40%;
  height: 2px;
  background-color: #44ba00;
  margin: 0.2rem auto; /* Center underline below headings */
  transition: width 0.3s ease;
}

.footer-h3:hover::after {
  width: 80%; /* Animate underline on hover */
  text-decoration: none;
}

.remove-underline {
  text-decoration: none;
  color: #333;
  font-size: 0.95rem;
  display: block;
  margin-top: 0.2rem;
  transition: color 0.3s ease;
}

.remove-underline:hover {
  color: #44ba00; /* Change color on hover */
  text-decoration: none;
}

/* Contact Section Styling */
.part-4 a {
  display: block;
  color: #333;
  font-size: 0.95rem;
  margin: 0.5rem 0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.part-4 a:hover {
  color: #44ba00;
  text-decoration: none;
}

/* Bottom Footer Section */
.footer-3 {
  text-align: center;
  padding: 0.5rem;
  background-color: #333;
  color: white;
  font-size: 0.9rem;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .footer-2 {
    flex-direction: column; /* Stack sections vertically for mobile */
    padding: 1rem;
    align-items: center; /* Center content on mobile */
  }

  .part-1 img {
    height: 5rem;
    width: 5rem;
  }

  .social-div a img {
    height: 1.8rem;
    width: 1.8rem;
    margin-bottom: 0.6rem;
  }

  .part-2, .part-3, .part-4 {
    text-align: center;
    margin-bottom: 0.2rem;
  }

  .remove-underline, .part-4 a {
    font-size: 0.85rem; /* Slightly smaller font for mobile */
  }
}


.footer-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  height: 2rem;
}
