nav {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between logo and other items */
  background: linear-gradient(
    to right,
    #09150a,
    #0a4e0d
  ); /* Green gradient background */
  color: white;
  padding: 0 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Deeper shadow for better separation */
  position: fixed; /* Fix the navbar to the top */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.logo-cont {
  flex: 0 0 auto; /* Logo on the left */
}

.logo-img {
  height: 5rem; /* Adjust the size as needed */
  cursor: pointer;
}

.onlyhomecart {
  text-decoration: none;
  color: white;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align menu items to the right */
}

.nav-links {
  display: flex; /* Display links in a row */
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.links {
  margin-left: 1rem; /* Adjust margin for spacing between links */
}

.links > a,
.links > Link {
  text-decoration: none;
  color: white;
  margin: 0 1rem; /* Margin between links */
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth transition for color */
}

.links > a:hover,
.links > Link:hover {
  color: rgb(24, 17, 24); /* Lighter green color on hover */
  text-decoration: none;
}

/* Styles for hamburger menu */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column; /* Stack the lines vertically */
  justify-content: space-around; /* Space them evenly */
  width: 30px; /* Width of the hamburger */
  height: 24px; /* Height of the hamburger */
  background-color: transparent; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Change cursor on hover */
  transition: transform 0.3s ease; /* Smooth transition */
  
}

.hamburger div {
  width: 100%; /* Full width for the lines */
  height: 3px; /* Thickness of the lines */
  background-color: white; /* Color of the lines */
  border-radius: 5px; /* Rounded corners for lines */
  transition: all 0.3s ease; /* Smooth transition for each line */
}

/* Add hover effect */
.hamburger:hover div {
  background-color: #e0f2f1; /* Change color on hover */
}

/* When the menu is open */
.hamburger.active div:nth-child(1) {
  transform: translateY(10px) rotate(45deg); /* Rotate the first line */
}

.hamburger.active div:nth-child(2) {
  opacity: 0; /* Hide the middle line */
}

.hamburger.active div:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg); /* Rotate the last line */
}

/* Mobile styles */
@media (max-width: 768px) {
  .menu {
    display: none; /* Hide menu by default */
    position: absolute;
    top: 4rem; /* Position below the navbar */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Centering */
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blurry effect */
    z-index: 3;
    width: 90%; /* Full width on mobile */
    flex-direction: column; /* Vertical alignment */
    align-items: center; /* Center items */
    transition: max-height 0.3s ease-in-out; /* Smooth transition */
    max-height: 0; /* Initially hidden */
    overflow: hidden; /* Hide overflow */
  }

  .menu.active {
    display: flex; /* Show menu when active */
    max-height: 500px; /* Adjust as needed */
  }

  .nav-links {
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Full width */
  }

  .links {
    width: 100%; /* Full width for each link */
    text-align: center; /* Center text */
    padding: 1rem 0; /* Padding for each link */
  }

  .links > a,
  .links > Link {
    color: black; /* Change text color to black */
  }

  .hamburger {
    display: flex; /* Show hamburger icon on mobile */
    margin-left: auto; /* Align to the right */
    z-index: 4; /* Ensure it is above other elements */
  }

  /* Cart count styles for the hamburger icon */
  .cart-count {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 50%;
  }
}
