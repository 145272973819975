.payment-container {
  max-width: 600px;
  margin: 4rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 80vh;
}

h2,
h3 {
  text-align: center;
  color: #333;
}

.payment-summary {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-form {
  display: flex;
  flex-direction: column;
}

.payment-form label {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #555;
}

.payment-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.pay-button {
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.pay-button:hover {
  background-color: #218838;
}
