/* Home Section */
.home {
  height: 67vh;
  background-color: rgb(45, 61, 12); /* Removed duplicate background-color */
  background-image: url("../images/home.jpg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  

  margin: 0;
  background-size: 80% 100%;
  transition: background-size 0.5s ease, background-position 0.5s ease; /* Smooth transitions */
}

.home:hover {
  background-size: 85% 105%; /* Slightly increase the background size on hover */
  background-position-x: center; /* Shift background position to center */
}

/* Home Content */
.home-cont {
  display: flex;
  flex-direction: column;
  color: yellow;
  align-items: flex-start;
  padding-top: 20vh;
  margin-left: 10%;
}

/* Headings and Subheadings */
#change-color,
#change-color2 {
  font-family: "Arial", sans-serif; /* Use a clean sans-serif font */
  color: white; /* White text color for contrast */
  text-align: center; /* Center align the heading */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  letter-spacing: 2px; /* Spacing between letters for a more elegant look */
  opacity: 0; /* Start hidden for animation */
}

#change-color3 {
  color: #e0f2f1; /* Lighter greenish color for the subheading */
  text-align: center; /* Center align the subheading */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  opacity: 0; /* Start hidden for animation */
}

/* Text Lines */
.line-1 {
  font-size: 3rem;
  font-weight: 500;
  opacity: 0; /* Start hidden for animation */
}

.line-2 {
  font-size: 3.5rem;
  font-weight: 900;
  opacity: 0; /* Start hidden for animation */
}

.line-3 {
  font-weight: 500;
  opacity: 0; /* Start hidden for animation */
}

/* Button Styles */
.din-btn {
  font-size: 1.2rem;
  color: white;
  border: none;
  font-weight: bold;
  background: linear-gradient(to right, #4caf50, #2e7d32);
  text-decoration: none;
  padding: 0.8rem 1.6rem;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.din-btn:hover {
  text-decoration: none;
  background: linear-gradient(to right, #66bb6a, #4caf50);
  transform: translateY(-4px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}

.din-btn:active {
  transform: translateY(-2px); /* Keep the lift effect subtle on click */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Reduce shadow on click */
}

/* Div-1 Styles */
.div-1 {
  background-color: #44ba00;
  height: 15vh;
  width: 60%;
  position: absolute;
  left: 20%;
  top: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .din-btn {
    width: 50%;
    font-size: 1rem;
  }

  .home {
    height: 40vh;
  }

  .home-cont {
    padding-top: 5rem;
  }

  .div-1 {
    display: none;
  }

  .line-1 {
    font-size: 2rem;
  }

  .line-2 {
    font-size: 2.5rem;
  }
}

/* Div-2 Styles */
.div-2 {
  height: 34vh;
  width: 60%;
  border: 1px solid grey;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.img-cont > img {
  height: 38vh;
  width: 90%;
  margin-left: 1rem;
}

/* About Div Styles */
.about-div {
  width: 60%;
  font-size: 0.8rem;
  color: rgb(45, 61, 12);
}

/* Learn Button Styles */
#learn-btn {
  background-color: #2ca729;
  margin-left: 30%;
  color: white;
  border: none;
  width: 25%;
}

.learn-btn:hover {
  color: black;
}

@media (max-width: 768px) {
  .div-2 {
    width: 100%;
    margin-top: 3rem;
  }

  .about-div {
    width: 60%;
    font-size: 0.5rem;
    color: rgb(45, 61, 12);
  }

  #learn-btn {
    width: 80%;
    margin-left: 10%;
  }
}

/* About Section */
.about {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #44ba00 25%, transparent 0%) 50% 1;
  color: #2ca729;
}

/* Services Container */
.services-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.services-cont > h3 {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #44ba00 50%, transparent 0%) 100% 1;
  color: #44ba00;
}

/* Slides Container */
.slides {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Box-1 Styles */
.box-1 {
  height: 30vh;
  width: 14%;
  border: 1px solid grey;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;
  background-color: #2ca729;
  border: none;
  font-size: 0.6rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.box-1:hover {
  transform: scale(1.05); /* Slight scaling effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.img-div > img {
  height: 5rem;
  width: 5rem;
  /* Removed 'alt' from CSS as it's an HTML attribute */
}

.img-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 50% 30px; /* Adjust the values as needed */
  border-bottom-right-radius: 50% 30px; /* Adjust the values as needed */
  background-color: #d0d0d0;
  padding: 20px;
  color: #ffffff;
}

/* Service Titles */
.h3-services {
  font-size: 0.7rem;
  color: black;
}

/* Media Queries for Slides */
@media (max-width: 768px) {
  .slides {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }

  .h3-services {
    font-size: 0.5rem;
    color: black;
  }

  .box-1 {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    font-size: 0.5rem;
    border: 10px solid grey; /* Added 'px' to border */
    margin-bottom: 2rem;
    text-transform: capitalize;
  }
}

/* Curve Div Styles */
.curve-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  color: white;
  padding-left: 0.5rem;
}

#change-font > p {
  color: white;
}

/* Valuate Div Styles */
.valuate-div {
  margin: auto;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plant-img {
  width: 50%;
  background: url(../images/recycle.png);
  background-size: contain; /* Use 'contain' to make the image fit entirely within the container */
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-position: center center; /* Center the image both horizontally and vertically */
  height: 100%;
}

.valuate-para {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40vh;
  width: 50%;
  font-size: 1rem;
  background-color: #f7e5e5;
  padding: 1rem;
}

.changepage {
  color: #44ba00;
  text-transform: capitalize;
  text-decoration: underline;
}

.valuate-para > h3 {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #44ba00 40%, transparent 0%) 100% 1;
}

/* Media Queries for Valuate Div */
@media (max-width: 768px) {
  .valuate-div {
    height: 35vh;
    border-bottom: 2rem;
  }

  #change-font > p {
    font-size: 0.6rem;
  }

  .valuate-para {
    font-size: 0.5rem;
    text-transform: capitalize;
    height: 35vh;
  }

  .valuate-para > h3 {
    font-size: 0.6rem;
    border-top: 1px solid;
    border-image: linear-gradient(to right, #44ba00 40%, transparent 0%) 100% 1;
  }
}

/* Supplies Div Styles */
.supplies-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50vh;
  background-color: #f7e5e5;
  font-size: 1rem;
}

.left-div {
  width: 50%;
  padding: 1rem;
}

.left-div > h3 {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #44ba00 40%, transparent 0%) 100% 1;
}

.dust {
  display: flex;
  margin: 2rem 0;
}

.right-div {
  width: 50%;
  background: url(../images/plant.jpg);
  background-size: cover; /* Use 'contain' to make the image fit entirely within the container */
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-position: center center; /* Center the image both horizontally and vertically */
  height: 100%;
}

@media (max-width: 768px) {
  .supplies-div {
    width: 100%;
    height: 35vh;
    font-size: 0.5rem;
    text-transform: capitalize;
  }

  .left-div > h3 {
    font-size: 0.6rem;
  }

  .left-div {
    padding: 1rem;
  }

  .dust {
    margin: 0;
  }

  .dust > img {
    height: 1.8rem;
    width: 1.8rem;
  }

  .right-div {
    width: 50%;
  }
}

/* Navigation Home */
.nav-home {
  text-decoration: none;
  margin-top: 1rem;
}

/* Warning Icon */
.warning {
  margin-left: 1rem;
  height: 2rem;
  width: 2rem;
}

/* Join Button Styles */
#join-btn {
  font-size: 0.9rem;
  width: 40%;
  padding: 0 2rem;
  height: 1.6rem;
}

@media (max-width: 768px) {
  .warning {
    margin-left: 1rem;
    height: 1rem;
    width: 1rem;
  }

  #join-btn {
    width: 80%;
    height: 1.5rem;
  }
}

@media (max-width: 380px) {
  .home {
    height: 35vh;
  }

  .home-cont {
    padding-top: 4rem;
    margin-left: 5%;
    margin-top: 2rem;
  }

  #change-color,
  #change-color2 {
    font-size: 1rem; /* Reduce font size */
    letter-spacing: 1px; /* Slightly reduce letter spacing */
  }

  .line-1 {
    font-size: 0.8rem;
  }

  .line-2 {
    font-size: 0.8rem;
  }

  .div-1 {
    top: 18rem;
    width: 90%;
    height: 15vh;
    font-size: 0.5rem;
  }

  .div-2 {
    width: 100%;
    margin-top: 2rem;
    height: auto;
    padding: 1rem;
  }

  .about-div {
    width: 100%;
    font-size: 0.3rem;
  }

  .din-btn {
    width: 60%;
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  #learn-btn {
    width: 90%;
    margin-left: 5%;
  }

  .slides {
    display: grid;
    grid-template-columns: 1fr;
  }

  .box-1 {
    width: 90%;
    height: auto;
    margin-bottom: 1rem;
  }

  .img-div > img {
    height: 4rem;
    width: 4rem;
  }

  .valuate-div {
    height: auto;
    margin-bottom: 2rem;
  }

  .valuate-para {
    font-size: 0.3rem;
    width: 90%;
    padding: 0.5rem;
  }

  .plant-img {
    width: 80%;
    height: auto;
  }

  .supplies-div {
    flex-direction: column;
    height: auto;
  }

  .left-div,
  .right-div {
    width: 100%;
  }

  .right-div {
    background-size: contain;
  }

  .dust > img {
    height: 1.5rem;
    width: 1.5rem;
  }

  #join-btn {
    width: 90%;
    height: 1.4rem;
  }

  .warning {
    height: 1.5rem;
    width: 1.5rem;
  }
}

/* Review Carousel */
.review-carousel {
  margin: 3rem auto;
  width: 80%;
  text-align: center;
}

.carousel-review-item {
  background-color: darkgreen;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.carousel-review-item p {
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  text-transform: capitalize;
}

.carousel-review-item small {
  color: #888;
  font-size: 0.9rem;
}

.star {
  font-size: 1.5rem;
  color: white; /* Default star color */
  margin-right: 5px;
}

.star.filled {
  color: darkgreen; /* Filled star color */
}

.star-rating-display {
  margin-top: 10px;
}

/* Sliding in from different directions for text */
@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Add sliding animations to the heading and text elements */
#change-color {
  animation: slide-in-left 1s ease-in-out forwards; /* Added 'forwards' to retain final state */
}

#change-color2 {
  animation: slide-in-right 1s ease-in-out forwards;
}

.line-1 {
  animation: slide-in-bottom 0.6s ease-in-out forwards;
}

.line-2 {
  animation: slide-in-top 0.8s ease-in-out forwards;
}

.line-3 {
  animation: slide-in-left 1s ease-in-out forwards;
}

/* Ensure text starts hidden and then animates in */
#change-color,
#change-color2,
.line-1,
.line-2,
.line-3,
#change-color3 {
  opacity: 0;
  /* Animation fills forwards to keep final state */
}
