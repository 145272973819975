.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2,
h3 {
  text-align: center;
  color: #333;
}

.cart-summary,
.address-form {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-items {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.cart-item-image {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  border-radius: 5px;
}

.cart-item-details {
  flex: 1;
}

.cart-total {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-top: 1rem;
}

.address-form form {
  display: flex;
  flex-direction: column;
}

.address-form label {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #555;
}

.address-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%; /* Ensure inputs take full width */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.address-form .button {
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.address-form .button:hover {
  background-color: #218838;
}
