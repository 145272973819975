.review-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
}

.review-form {
  margin-bottom: 20px;
}

.review-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}

.review-list {
  margin-top: 20px;
}

.review-item {
  background-color: green;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.review-item p {
  font-size: 16px;
  margin-bottom: 5px;
}

.review-item small {
  color: #6c757d;
  font-size: 12px;
}

.star {
  cursor: pointer;
  font-size: 2rem;
  color: #ccc; /* Default star color */
}

.star.filled {
  color: gold; /* Highlighted star color */
}

.star-rating {
  margin-top: 10px;
  margin-bottom: 20px;
}

.star-rating-display {
  margin-top: 5px;
}

.review-item {
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
