.container {
  max-width: 600px;
  margin: 3.5rem auto;
  padding: 20px;
  min-height: 90vh;
}

.category {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.category.open {
  background-color: #f9f9f9;
}

.subcategory-list {
  margin: 10px 0 0 20px;
  padding: 0;
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.category.open .subcategory-list {
  max-height: 500px; /* Adjust this value to be large enough to fit the tallest subcategory list */
}

.subcategory-item {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}

/* New CSS class to highlight the price */
.price-highlight {
  font-weight: bold;
  color: #e74c3c; /* You can change this color to any color you prefer */
  background-color: #f9f9f9; /* Optional: Add a background color to highlight the price */
  padding: 2px 5px;
  border-radius: 3px;
}
