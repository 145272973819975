.react-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
    outline: none; /* Remove default outline */
    padding: 2rem; /* Add padding */
    width: 90%;
    max-width: 500px; /* Max width for larger screens */
    animation: fadeIn 0.3s ease; /* Animation for opening */
  }
  
  /* Transition effect for closing the modal */
  .react-modal--close {
    animation: fadeOut 0.3s ease forwards; /* Animation for closing */
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    text-align: center; /* Center text */
  }
  
  h2 {
    margin-bottom: 1rem; /* Space below heading */
    color: #44ba00; /* Theme color for the heading */
  }
  
  p {
    margin-bottom: 1.5rem; /* Space below paragraph */
    color: #555; /* Darker color for the text */
  }
  
  button {
    background-color: #44ba00; /* Theme color for the button */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 0.5rem 1rem; /* Padding for the button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  button:hover {
    background-color: #36a500; /* Darker shade on hover */
  }
  
  /* Keyframes for animations */
  @keyframes fadeIn {
    from {
      opacity: 0; /* Start invisible */
      transform: translate(-50%, -60%); /* Start higher up */
    }
    to {
      opacity: 1; /* Fully visible */
      transform: translate(-50%, -50%); /* Centered */
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1; /* Start fully visible */
    }
    to {
      opacity: 0; /* Fade out */
    }
  }
  