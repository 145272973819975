.order-img {
  height: 30vh;
  background: url(../images/orderpage.jpg);

  background-size: cover; /* Use 'contain' to make the image fit entirely within the container */
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  position: absolute;
  height: 50vh;
  top: 50%;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

label {
  margin-left: 1rem;
  font-weight: 600;
}
.form-style-3 {
  max-width: 60%;
  padding-top: 1rem;
}

.form-style-3 label {
  display: block;
  margin-bottom: 10px;
}
.form-style-3 input[type="name"],
.form-style-3 input[type="date"],
.form-style-3 input[type="datetime"],
.form-style-3 input[type="number"],
.form-style-3 input[type="time"],
.form-style-3 input[type="email"],
.form-style-3 select,
.form-style-3 textarea {
  border: none;
  border-bottom: 1px solid grey;
  color: grey;
  padding: 5px 8px;
  font-size: small;

  width: 60%;
}
.phonenumber {
  border: none;
  border-bottom: 1px solid grey;
  font-size: small;
  width: 60%;
}

.blank-div {
  height: 40vh;
}

.schedule {
  height: 2.5rem;
  margin: 1rem 0 1rem 10%;
  border: none;
  font-size: large;
  font-weight: 600;
  width: 80%;
}

.services-container {
  height: 50vh;
  background: url(../images/plant.jpg) center center no-repeat;
  position: relative;
  background-size: cover;
  color: white;
}

.services-about {
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .form {
    width: 100%;
  }

  .form-style-3 {
    max-width: 90%;
    font-size: 0.8rem;
  }
  .schedule {
    font-size: medium;
  }

  .services-about {
    font-size: 0.6rem;
  }
}
