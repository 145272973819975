.loading-div {
  height: 100vh;
  display: flex;
  flex-direction: column; /* Align loader and text vertically */
  justify-content: center;
  align-items: center;
  background-color: #f3fdf6; /* Soft light green for a more eco-friendly feel */
}

.loader {
  position: relative;
  width: 6rem; /* Slightly larger loader for emphasis */
  height: 6rem;
  border: 0.5rem solid #EAF0F6; /* Light border to add contrast */
  border-radius: 50%;
  border-top: 0.5rem solid #44ba00; /* Green top border for rotating effect */
  animation: spinner 1.5s linear infinite; /* Faster spinning for more dynamic effect */
  box-shadow: 0 0 20px rgba(68, 186, 0, 0.5); /* Subtle glow around loader */
}

/* Recycle Icon inside the loader */
.loader::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.5rem; /* Larger recycle icon for better visibility */
  height: 2.5rem;
  background: url('../images/recycle-icon.png') no-repeat center center;
  background-size: contain;
  transform: translate(-50%, -50%);
  opacity: 0.9; /* Slight transparency for soft touch */
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Friendly loading message below the loader */
.loading-text {
  margin-top: 1.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #44ba00;
  text-align: center;
  letter-spacing: 1px; /* Slight spacing for readability */
  text-transform: uppercase; /* For a more professional look */
  animation: fadeIn 2s ease-in-out infinite; /* Soft fade-in animation */
}

@keyframes fadeIn {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}
