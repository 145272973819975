.plants-container {
  margin: 4rem auto;
  max-width: 80%;
  padding: 20px;
  text-align: center;
}

.cart-info {
  text-align: left;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.plants-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.plant-card {
  position: relative;
  width: 250px;
  margin: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plant-image {
  width: 100%;
  height: 300px; /* Set a fixed height */
  object-fit: cover; /* Maintain aspect ratio while filling the height */
  border-radius: 10px;
}

.add-to-cart-btn {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #2ecc71;
}

h2 {
  margin-bottom: 20px;
  color: #2c3e50;
}

h3 {
  color: #27ae60;
}

p {
  color: #555;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin: 5px 0;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin: 2rem 0; /* Add margin to both top and bottom */
}

.filter-buttons button {
  background-color: #27ae60;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-buttons button:focus {
  outline: none;
  background-color: #2c3e50;
  color: white;
}

.plant-card:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.plant-description,
.plant-sunlight,
.plant-features {
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: max-height 0.3s ease, white-space 0.3s ease;
}

.plant-card:hover .plant-description,
.plant-card:hover .plant-sunlight,
.plant-card:hover .plant-features {
  max-height: 100px;
  white-space: normal;
  overflow-y: auto;
}

.plant-card .description-overlay,
.plant-card .sunlight-overlay,
.plant-card .features-overlay {
  position: relative;
  overflow: hidden;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.search-input {
  width: 80%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.search-btn {
  width: 30%;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  background-color: #44ba00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-btn:hover {
  background-color: #369d00;
}

/* Mobile View */
@media only screen and (max-width: 600px) {
  .plant-card {
    width: 90%;
    margin: 20px auto;
  }

  .plant-image {
    height: 400px; /* Increase height for mobile */
  }
}
